// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';

// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes
import './assets/scss/Saas.scss';
import RestrictedModal from './utility-components/RestrictedModal';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dakr import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
 const  App = () =>  {

    // axios.interceptors.response.use(function (response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data

    //     return response;
    //   }, function (error) {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     toast.error(`${error.response.status}: ${error.response.data.errors[0].msg}`)

    //     return Promise.reject(error);
    //   });

    return (
        <BrowserRouter 
        // basename='/srx/faci'
        >
            <UserProvider>
                <Routes></Routes>
                <ToastContainer
                    position="bottom-right"
                    theme="light"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    icon
                    draggable
                    pauseOnHover
                />
                <RestrictedModal />
            </UserProvider>  
        </BrowserRouter>
    )
}

export default App;
