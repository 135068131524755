import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { CapGuideReferralPartnerApi } from '../api/CapGuideApi';
import jwt_decode from "jwt-decode";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    const [capGuideReferralPartnerToken, setCapGuideReferralPartnerToken] = useState(null);

    const [userLoading, setUserLoading] = useState(true);

    let history = useHistory();
    let location  = useLocation();

    useEffect(() => {

        let localStorageCapGuideReferralPartnerToken = localStorage.getItem('CAP_GUIDE_REFERRAL_PARTNER_TOKEN');

        if(localStorageCapGuideReferralPartnerToken !== undefined && localStorageCapGuideReferralPartnerToken !== null){
            axios.get(process.env.REACT_APP_CAP_GUIDE_REFERRAL_PARTNER_API_BASE_URL + '/verifyToken', {
                headers: {
                    'Authorization': 'Bearer ' + localStorageCapGuideReferralPartnerToken
                }
            })
            .then(res => {
                CapGuideReferralPartnerApi.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageCapGuideReferralPartnerToken;
                setCapGuideReferralPartnerToken(localStorageCapGuideReferralPartnerToken);
                setUser(res.data)
            })
            .catch(err => {
                CapGuideReferralPartnerApi.defaults.headers.common['Authorization'] = undefined;
                setCapGuideReferralPartnerToken(null);
                localStorage.removeItem('CAP_GUIDE_REFERRAL_PARTNER_TOKEN');
                // history.push('/account/login');
            })
            .finally(() => setUserLoading(false))
        } else {
            setUserLoading(false)
            setCapGuideReferralPartnerToken(null);
        }

    }, [])

    const loginUser = async (loginInfo) => {
        setUserLoading(true);

        try{

            const tokenRequest = await axios.post(process.env.REACT_APP_CAP_GUIDE_REFERRAL_PARTNER_API_BASE_URL + '/login', loginInfo)

            // When a user signs in with a temp password the server responds with a 307 status. The user needs to be redirected to the complete sign up page
            if(tokenRequest.status === 307)
                return history.push('/account/new-password?email=' + encodeURIComponent(loginInfo.email) + '&temp-password=' + encodeURIComponent(loginInfo.password))
            
            CapGuideReferralPartnerApi.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRequest.data.token;
            localStorage.setItem('CAP_GUIDE_REFERRAL_PARTNER_TOKEN', tokenRequest.data.token)
            toast.success('User Logged In');
            setCapGuideReferralPartnerToken(tokenRequest.data.token);
            setUser(tokenRequest.data);
            
        } catch(err){
            if(err.response.status === 307)
                return history.push('/account/new-password?email=' + encodeURIComponent(loginInfo.email) + '&temp-password=' + encodeURIComponent(loginInfo.password))
            setCapGuideReferralPartnerToken(null);
            CapGuideReferralPartnerApi.defaults.headers.common['Authorization'] = undefined;
            toast.error('Error: Login failed');
        } finally {   
            setUserLoading(false)
        }
	}

    const logoutUser = () => {
        CapGuideReferralPartnerApi.defaults.headers.common['Authorization'] = undefined;
        localStorage.removeItem('CAP_GUIDE_REFERRAL_PARTNER_TOKEN');
        setUser(null)
        setCapGuideReferralPartnerToken(null)
        toast.success('User Logged Out');
        history.push('/account/login');
    }

    // const logoutUser = () => {
    //     localStorage.removeItem('OMNI_TOKEN');
    //     OmniApi.defaults.headers.common['token'] = undefined;
    //     XOmniApi.defaults.headers.common['token'] = undefined;
    //     toast.success("User logged out", {
    //         theme: 'colored',
    //         position: "top-center",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    //     setUser('');
    //     history.push('/account/login');
    //     // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // } 


    
    return (
        <UserContext.Provider value={{ user, setUser, userLoading, loginUser, logoutUser, capGuideReferralPartnerToken, setCapGuideReferralPartnerToken }}>
            {props.children}
        </UserContext.Provider>
    );
}

