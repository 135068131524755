import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Button, FormGroup } from 'reactstrap';
import { Icon } from '@iconify/react';
import accountClock from '@iconify/icons-mdi/account-clock';
import accountCancel from '@iconify/icons-mdi/account-cancel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

let pendingMessage = (
    <div>
        <div style={{ fontSize: '21px' }}><b>We’re excited to get to work with you!</b></div ><br />

        <div style={{ fontSize: '16px' }}><b>We appreciate your trust in our team by signing up for the referral program.<br /><br />
        Your application is currently pending, our team will review your application and inform you of the final decision.<br /><br />

        If you have any questions please contact us at <a href="mailto:partners@capguidefunding.com">partners@capguidefunding.com</a></b></div>
    </div>
)

let rejectedMessage = (
    <div>
        <div style={{ fontSize: '21px' }}><b>Please accept our apologies...</b></div ><br />

        <div style={{ fontSize: '16px' }}><b>We are sorry to inform you that your application has been denied.<br /><br />
        For further inquiries, please contact us at <a href="mailto:partners@capguidefunding.com">partners@capguidefunding.com</a></b>
        </div>
    </div>
)

const RestrictedModal = ({ status }) => {

    let history = useHistory();

    const { user } = useContext(UserContext);

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if(user && user?.referralPartnerStatus !== 'approved'){
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }, [user])

    // function toggle(){
    //     setIsOpen(!isOpen)
    // }

    return(
        <Modal isOpen={isOpen} centered>
        {/* <ModalHeader className={`bg-${user?.referralPartnerStatus === 'pending' ? 'warning' : 'danger'} text-white`}>
            Your status is {user?.referralPartnerStatus}
        </ModalHeader> */}
        <ModalBody style={{ minHeight: '300px' }} className="d-flex justify-content-center mt-4">
            <div className='text-center' style={{ color: `${user?.referralPartnerStatus === 'pending' ? '#ffcd30' : '#ff4b4b'}` }}>
            {user?.referralPartnerStatus === 'pending' ?
            <Icon icon={accountClock} style={{ border: '5px solid #FFECB6', borderRadius: '0px' }} width='130' className='p-2 mb-2'/>
            :
            <Icon icon={accountCancel} style={{ border: '5px solid #FED0D9', borderRadius: '0px' }} width='130' className='p-2 mb-2'/>}
            <div style={{ color: '#383f4b' }} className="p-2">{user?.referralPartnerStatus === 'pending' ? pendingMessage : rejectedMessage}</div>
            <FormGroup>
            <Button color="formalNavy" className='mt-2' onClick={() => history.push('/account/logout')}>Back to Login</Button>
            </FormGroup>
            </div>
        </ModalBody>
    </Modal>
    )
}

export default RestrictedModal;