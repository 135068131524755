import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import { UserContext } from '../contexts/UserContext';
import NewPassword from '../pages/auth/NewPassword';
import ConfirmNewUser from '../pages/auth/ConfirmNewUser';
import Deals from '../custom-components/Deals';
import DealDetail from '../custom-components/DealDetail';
import Dashboard from '../custom-components/Dashboard';
import ReferralForm from '../custom-forms/ReferralForm';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmResetPasswordRequest from '../pages/auth/ConfirmResetPasswordRequest';
import ResetPassword from '../pages/auth/ResetPassword';
import ConfirmResetPassword from '../pages/auth/ConfirmResetPassword';
import RestrictedComponent from '../custom-components/RestrictedComponent';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboards/Ecommerce'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => {

    let history = useHistory()

    const { capGuideReferralPartnerToken, loginUser, userLoading, setUser } = useContext(UserContext);

    const [loading, setLoading] = useState(true); 

    // only approved referral partners can access these routes
    const restrictedRoutes = ['/deals']

    useEffect(() => {

        let destinationRoute = rest.path;

        // check if destinationRoute is restricted
        if(restrictedRoutes.indexOf(destinationRoute) === -1){
            return setLoading(false)
        }

        if(capGuideReferralPartnerToken){
            axios.get(process.env.REACT_APP_CAP_GUIDE_REFERRAL_PARTNER_API_BASE_URL + '/verifyToken', {
                headers: {
                    'Authorization': 'Bearer ' + capGuideReferralPartnerToken
                }
            })
            .then(res => {
                setUser(res.data)
                if(res.data.referralPartnerStatus !== 'approved')
                    history.push('/restricted')
                else setLoading(false)
            })
        }
    }, [])

    return (
        <Route
            {...rest}
            render={props => {
                if (!capGuideReferralPartnerToken) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
                }

                // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }
                if(userLoading || loading)
                    return (
                        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                        <div>
                        <h4>Loading...</h4>
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            </div>
                        </div>
                        )

                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );

}

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to={process.env.REACT_APP_DEFAULT_REDIRECT_ROUTE} />,
    route: PrivateRoute,
};

const dashboardRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-desktop',
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Dashboard,
};

const dealsRoute = {
    path: '/deals',
    name: 'Deals',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-money-bill',
    header: <hr className='p-0 mt-1 left-sidebar-divider d-none d-lg-block'/>,
    // header: <Badge className='py-1 px-2 badge badge-outline-light' style={{ fontSize: '13px' }}><span>Navigation</span></Badge>,
    exact: true,
    component: Deals,
};

// dashboards
// const formsRoute = {
//     path: '/forms',
//     name: 'Forms',
//     icon: 'uil-file-alt',
//     header: <hr />,
//     children: [
//         {
//             path: '/forms/referral',
//             name: 'Referral Form',
//             component: ReferralForm,
//             route: PrivateRoute,
//         }
//     ],
// };

const formsRoute = {
    path: '/forms/referral',
    name: 'Referral Form',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-file-alt',
    // header: 'Forms',
    exact: true,
    component: ReferralForm,
};

const appRoutes = [
    dealsRoute,
    formsRoute
];

const hiddenRoutes = {
    children: [
        {
            path: ['/restricted'],
            name: 'Restricted',
            component: RestrictedComponent,
            route: PrivateRoute,
            exact: true
        }
    ],
};

// const otherRoutes = {
//     path: '/detail',
//     name: 'Detail',
//     children: [
//         {
//             path: '/deals/:dealId',
//             name: 'Deal Detail',
//             route: PrivateRoute,
//             roles: ['Admin'],
//             component: DealDetail
//         }
//     ]
// };

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/confirm-reset-password-request',
            name: 'Confirm Reset Password Request',
            component: ConfirmResetPasswordRequest,
            route: Route,
        },
        {
            path: '/account/reset-password',
            name: 'Confirm Reset Password Request',
            component: ResetPassword,
            route: Route,
        },
        {
            path: '/account/confirm-reset-password',
            name: 'Confirm Reset Password',
            component: ConfirmResetPassword,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/new-password',
            name: 'New Password',
            component: NewPassword,
            route: Route,
        },
        {
            path: '/account/confirm-new-user',
            name: 'Confirm New Password',
            component: ConfirmNewUser,
            route: Route,
        }
    ],
};



// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes, authRoutes, hiddenRoutes];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
