import React, { Component, useContext,useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import { registerUser } from '../../redux/actions';
import { isUserAuthenticated } from '../../helpers/authUtils';
import LoaderWidget from '../../components/Loader';
import logo from '../../assets/images/partners-logo-on-white.svg';
import { UserContext } from '../../contexts/UserContext';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Helmet } from "react-helmet";

const NewPassword = () => {

    let history = useHistory();
    let location = useLocation();
    const query = new URLSearchParams(location.search);

    const [signupInfo, setSignupInfo] = useState({
        email: query.get('email'),
        tempPassword: query.get('temp-password'),
        password: '',
        confirmPassword: ''
    });

    const { capGuideReferralPartnerToken } = useContext(UserContext);

    useEffect(() => {
        if(capGuideReferralPartnerToken)
            history.replace(process.env.REACT_APP_DEFAULT_REDIRECT_ROUTE)
        if(!query.get('email') || !query.get('temp-password'))
            history.replace('/account/login')
    }, [capGuideReferralPartnerToken])

    const [loading, setLoading] = useState(false);

    async function handleValidSubmit(){
        if(signupInfo.password !== signupInfo.confirmPassword) return toast.error('Passwords do not match')
        try{
            setLoading(true)
            await axios.post(process.env.REACT_APP_CAP_GUIDE_REFERRAL_PARTNER_API_BASE_URL + '/createUser', signupInfo)
            history.push('/account/confirm-new-user?email=' + encodeURIComponent(signupInfo.email))
        } catch(err){
            toast.error('Error: ' + err.message)
            setLoading(false)
        }
    }

        return (
            <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Set New Password | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
                <>
                <Row className='auth-window m-0'>
                    <Col xs={12} sm={6} md={5} lg={4} className="p-0 d-flex flex-column justify-content-between bg-auth">
                        <div className='d-flex w-100 justify-content-center mt-4'>
                        <img src={logo} alt="" height="50" />
                        </div>
                        <div className='mx-4' style={{color: '#00307c'}}>
                                            <h2 className="text-formalNavy text-center mt-0 font-weight-bold mb-3">
                                            Set New Password
                                            </h2>
                                    <Row className='justify-content-center'>
                                        <Col md={10}>
                                        {loading && <LoaderWidget />}
                                        
                                        <div className="text-center w-75 m-auto">
                                                <p className="text-skyBlue mb-4">
                                                    Please set a new password for <b>{query.get('email')}</b>
                                                </p>
                                            </div>

                                            <AvForm onValidSubmit={handleValidSubmit}>

                                                <AvField
                                                    type="password"
                                                    name="password"
                                                    label="New Password"
                                                    placeholder="Enter new password"
                                                    value={signupInfo.password}
                                                    onChange={(e) => setSignupInfo({
                                                        ...signupInfo,
                                                        password: e.target.value
                                                    })}
                                                    required
                                                    validate={{
                                                        minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                                                    }}
                                                />

                                                <AvField
                                                    type="password"
                                                    name="confirm-password"
                                                    label="Confirm New Password"
                                                    placeholder="Confirm new password"
                                                    value={signupInfo.confirmPassword}
                                                    onChange={(e) => setSignupInfo({
                                                        ...signupInfo,
                                                        confirmPassword: e.target.value
                                                    })}
                                                    required
                                                    validate={{
                                                        minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
                                                    }}
                                                />

                                            <AvGroup className="mt-3">
                                                    <Button block color="formalNavy">Submit</Button>
                                                    <Button block color="formalNavy" outline onClick={() => history.push('/account/login')}>Back to Login</Button>
                                            </AvGroup>
                                            </AvForm>
                                        
                                        </Col>
                                    </Row>
                                {/* <Row className="mt-1">
                                <Col className="col-12 text-center">
                                    <p style={{ color: 'rgba(92,146,255,1)' }}>
                                        Don't have an account?{' '}
                                        <Link to="/account/register" className="ml-1" color='rgba(92,146,255,1)'>
                                            <b>Register</b>
                                        </Link>
                                    </p>
                                </Col>
                            </Row> */}
                            </div>
                            <div style={{ height: '10%' }}></div>
                    </Col>
                    <Col sm={6} md={7} lg={8} className='bg-column p-0 d-none d-sm-block'></Col>
                </Row>
            </>
        </React.Fragment>
            // <React.Fragment>
            //         <div className="account-pages mt-5 mb-5">
            //             <Container>
            //                 <Row className="justify-content-center">
            //                     <Col lg={5}>
            //                         <Card>
            //                             <div className="card-header pt-4 pb-4 text-center bg-primary">
            //                                 <Link to="/">
            //                                     <span>
            //                                         <img src={logo} alt="" height="50" />
            //                                     </span>
            //                                 </Link>
            //                             </div>

            //                             <CardBody className="p-4 position-relative">
            //                                 {/* preloader */}
            //                                 {loading && <LoaderWidget />}

            //                                 <div className="text-center w-75 m-auto">
            //                                     <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
            //                                         Set New Password
            //                                     </h4>
            //                                     <p className="text-muted mb-4">
            //                                         Please set a new password for <b>{query.get('email')}</b>
            //                                     </p>
            //                                 </div>

            //                                 <AvForm onValidSubmit={handleValidSubmit}>

            //                                     <AvField
            //                                         type="password"
            //                                         name="password"
            //                                         label="New Password"
            //                                         placeholder="Enter new password"
            //                                         value={signupInfo.password}
            //                                         onChange={(e) => setSignupInfo({
            //                                             ...signupInfo,
            //                                             password: e.target.value
            //                                         })}
            //                                         required
            //                                         validate={{
            //                                             minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
            //                                         }}
            //                                     />

            //                                     <AvField
            //                                         type="password"
            //                                         name="confirm-password"
            //                                         label="Confirm New Password"
            //                                         placeholder="Confirm new password"
            //                                         value={signupInfo.confirmPassword}
            //                                         onChange={(e) => setSignupInfo({
            //                                             ...signupInfo,
            //                                             confirmPassword: e.target.value
            //                                         })}
            //                                         required
            //                                         validate={{
            //                                             minLength: {value: 4, errorMessage: 'Password must include a minimum of 4 characters'}
            //                                         }}
            //                                     />

            //                                     <FormGroup>
            //                                         <Button color="success">Submit</Button>
            //                                     </FormGroup>
            //                                 </AvForm>
            //                             </CardBody>
            //                         </Card>
            //                     </Col>
            //                 </Row>

            //                 <Row className="mt-1">
            //                     <Col className="col-12 text-center">
            //                         <p className="text-muted">
            //                             Already have an account?{' '}
            //                             <Link to="/account/login" className="text-muted ml-1">
            //                                 <b>Login</b>
            //                             </Link>
            //                         </p>
            //                     </Col>
            //                 </Row>
            //             </Container>
            //         </div>
            // </React.Fragment>
        );
}

const mapStateToProps = state => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};

export default connect(
    mapStateToProps,
    { registerUser }
)(NewPassword);
