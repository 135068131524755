import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Table, Row, Col, Card, Badge, Progress, Modal, ModalHeader, ModalBody, CardBody, CardHeader } from 'reactstrap';
import { CapGuideReferralPartnerApi } from '../api/CapGuideApi';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import percentOutline from '@iconify/icons-mdi/percent-outline';
import handCoinOutline from '@iconify/icons-mdi/hand-coin-outline';
import handshakeOutline from '@iconify/icons-mdi/handshake-outline';
import cashIcon from '@iconify/icons-mdi/cash';
import walletOutline from '@iconify/icons-mdi/wallet-outline';
import chartTimelineVariant from '@iconify/icons-mdi/chart-timeline-variant';
import fileSign from '@iconify/icons-mdi/file-sign';
import officeBuilding from '@iconify/icons-mdi/office-building';
import PageTitle from '../components/PageTitle';
import percentBoxOutline from '@iconify/icons-mdi/percent-box-outline';
import calendarMonthOutline from '@iconify/icons-mdi/calendar-month-outline';
import accountBoxOutline from '@iconify/icons-mdi/account-box-outline';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import {Helmet} from "react-helmet";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const progressBarColor = (value) => {

    if(value === 'on-hold')
        return 'warning'
    
    value = parseInt(value)
    // if(value <= 25)
    //     return 'danger'
    // else if(value > 25 && value <= 50)
    //     return 'warning'
    // else if(value > 50 && value <= 75)
    //     return 'info'
    // else if(value > 75 && value < 100)
    //     return 'primary'
    // else if(value >= 100)
    //     return 'success'  
    // else if(value < 0)
    //     return 'red'
    // if(value > 0 && value < 100)
    //     return 'progress-lessThan100'
    // else if(value > 100)
    //     return 'progress-moreThan100'
    // else if(value < 0)
    //     return 'progress-lessThan0' 
    if(value >= 0 && value < 100)
        return 'light'
    else if(value > 100)
        return 'success'
    else if(value < 0)
        return 'danger' 
}

const Deals = props => {

    const { user } = useContext(UserContext);

    const [deals, setDeals] = useState(null);

    const [dealModal, setDealModal] = useState({
        open: false, 
        data: null
    });

    const [loading, setLoading] = useState(true)

    const toggleDealModal = (info = '') => {
        setDealModal({
            open: !dealModal.open,
            data: info
        })
    }

    useEffect(() => {
        // if(user.referralPartnerStatus === 'approved'){
        //     CapGuideReferralPartnerApi.get('/deals')
        //     .then(res => setDeals(res.data))
        //     .catch(err => toast.error('Error fetching deals'))
        // } else {
        //     setDeals([])
        // }


        CapGuideReferralPartnerApi.get('/deals')
            .then(res => setDeals(res.data))
            .catch(err => toast.error('Error fetching deals'))
            .finally(() => setLoading(false))
        
    }, [])

    console.log(deals)

    // useEffect(() => {
    //     if(user){
    //         if(user.referralPartnerStatus !== 'approved')
    //             setDeals([])
    //     }
    // }, [user, deals])

    const [sortBy, setSortBy] = useState({
        sortField: 'createdate',
        sortDirection: 'asc'
    })

    function sortByField(array){
        switch(sortBy.sortField){
            case 'createdate': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => new Date(a.createdate) > new Date(b.createdate) ? -1 : 1)
                else
                    return array.sort((a, b) => new Date(a.createdate) < new Date(b.createdate) ? -1 : 1)
            case 'client_name': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.client_name < b.client_name ? -1 : 1)
                else
                    return array.sort((a, b) => a.client_name > b.client_name ? -1 : 1)
            case 'progress': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => parseInt(a.progress) < parseInt(b.progress) ? -1 : 1)
                else
                    return array.sort((a, b) => parseInt(a.progress) > parseInt(b.progress) ? -1 : 1)
            case 'dealname': 
                if(sortBy.sortDirection === 'asc')
                    return array.sort((a, b) => a.dealname < b.dealname ? -1 : 1)
                else
                    return array.sort((a, b) => a.dealname > b.dealname ? -1 : 1)
        }
    }

    function onClickSort(field){
        if(field === sortBy.sortField){
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc'
            })
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc'
            })
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? 
            <Icon icon={arrowUp} color="black"/>
            :
            <Icon icon={arrowDown} color="black"/>
            }
        </>
    )

    if(loading)
    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Deals | ${process.env.REACT_APP_HEADER_TITLE}`}</title>
            </Helmet>
        <div>
        <h4>Loading Deals...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
    )

    // if(!deals.length)
    //     return(
    //         <>
    //         <Helmet>
    //             <meta charSet="utf-8" />
    //             <title>{`Deals | ${process.env.REACT_APP_HEADER_TITLE}`}</title>
    //         </Helmet>
    //         <Row className='mt-2'>
    //             <Col>
    //                 <Card style={{ outline: '2px dashed grey' }} className="shadow">
    //                     <CardBody className='text-center'>
    //                         <h4>There are no current deals</h4>
    //                     </CardBody>
    //                 </Card>
    //             </Col>
    //         </Row>
    //         </>
    //     )

    return(
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Deals | {process.env.REACT_APP_HEADER_TITLE}</title>
        </Helmet>
        <Row className='mt-2'>
            <Col>
                <Card className='mt-2'>
                    <CardBody className='pt-1'>
                    <h2>Deals</h2>
                <Table className="mb-0 mt-0" hover responsive>
                    <thead>
                        <tr>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('dealname')}>Deal Name {sortBy.sortField === 'dealname' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('client_name')}>Client Name {sortBy.sortField === 'client_name' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('progress')}>Progress {sortBy.sortField === 'progress' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Commission Type</th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => onClickSort('createdate')}>Date {sortBy.sortField === 'createdate' ? <SortCarets /> : <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortByField(deals).map((deal, index) => {
                            return (
                                <tr 
                                onClick={() => toggleDealModal(deal)}
                                key={index}>
                                    <th scope="row"
                                    width="20%"
                                    style={{
                                        verticalAlign: 'middle',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                    >{deal.dealname}</th>
                                    <td 
                                    width="10%"
                                    style={{ whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{deal.client_name}</td>
                                    <td width='40%'
                                    style={{ whiteSpace: 'nowrap' }}
                                    >
                                    <div><b>{
                                    deal.progress === 'on-hold' ?
                                    'Deal On Hold'
                                    :
                                    parseInt(deal.progress) < 100 ?  
                                    parseInt(deal.progress) < 0 ?
                                    'Deal Lost'
                                    :
                                    parseInt(deal.progress) === 0
                                    ?
                                    'Deal Submitted'
                                    :
                                    `${deal.progress}% Complete` 
                                    : 
                                    parseInt(deal.progress) > 100 ?
                                    'Completed'
                                    :
                                    'Clear To Close'
                                    }</b></div>
                                    <Progress
                                    className={`${deal.progress >= 100 || deal.progress === 'on-hold' ? '' : 'justify-content-end'}`}
                                    value={deal.progress === 'on-hold' ? 100 : parseInt(deal.progress) < 0 ? 100 : parseInt(deal.progress) > 100 ? 100 : 100 - deal.progress} 
                                    color={progressBarColor(deal.progress)}
                                    ></Progress>
                                    </td>
                                    <td width="10%" style={{ fontSize: '15px', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{deal.commission_type === 'client_discount' ? 
                                    <span><Icon icon={percentOutline} /> Client Discount</span> : 
                                    <span><Icon icon={handCoinOutline} /> Commission</span>}</td>
                                    <td width="10%" style={{ verticalAlign: 'middle' }}>{new Date(deal.createdAt).toLocaleDateString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
                {!deals.length && <h5 className='text-center mt-3'>No Deals to Display, <a href="/forms/referral">Click Here</a> to Submit a Deal</h5>}
                </CardBody>
                <DealModal data={dealModal.data} isOpen={dealModal.open} toggle={toggleDealModal}/>
                </Card>
            </Col>
        </Row>
        </>
    )
}

const DealModal = ({data, isOpen, toggle}) => {

    if(!isOpen)
     return ''

     const { id, client_name: clientName, createdate, dealname, dealtype, loan_amount, commission_type, property_type, progress } = data;

     function capitalizeFirstLetter(string = '') {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    return(
        <Modal
        isOpen={isOpen} toggle={toggle}>
            <ModalHeader 
            className='modal-colored-header bg-primary deal-modal-header'
            toggle={toggle}>
                {dealname}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className='text-center'>Progress:</div>
                        <div className='text-center'><b>{
                                    progress === 'on-hold'
                                    ?
                                    'Deal On Hold'
                                    :
                                    parseInt(progress) < 100 ?  
                                    parseInt(progress) < 0 ?
                                    'Deal Lost'
                                    :
                                    parseInt(progress) === 0
                                    ?
                                    'Deal Submitted'
                                    :
                                    `${progress}% Complete` 
                                    : 
                                    parseInt(progress) > 100 ?
                                    'Completed'
                                    :
                                    'Clear To Close'
                                    }</b></div>
                        <Progress
                            className='justify-content-end'
                            value={progress === 'on-hold' ? 100 : parseInt(progress) < 0 ? 100 : parseInt(progress) > 100 ? 100 : 100 - progress} 
                            color={progressBarColor(progress)}></Progress>
                    </Col>
                </Row>
                <hr />
                <div className='text-center'>
                <p><Icon icon={handshakeOutline} width="20" />  Deal Name:<br /><b>{dealname}</b></p>
                <hr/>
                <p><Icon icon={accountBoxOutline} width="20"/>  Client Name:<br /><b>{clientName}</b></p>
                <hr/>
                <p><Icon icon={fileSign} width="20"/>  Deal Type:<br /><b>{dealtype ? capitalizeFirstLetter(dealtype) : ''}</b></p>
                <hr/>
                <p><Icon icon={cashIcon} width="20"/>  Loan Amount:<br /><b>{formatter.format(loan_amount)}</b></p>
                <hr/>
                <p><Icon icon={percentBoxOutline} width="20"/>  Commission Type:<br /><b>{commission_type === 'client_discount' ? 
                                    <span>Client Discount</span> : 
                                    <span>Commission</span>}</b></p>
                <hr/>
                <p><Icon icon={officeBuilding} width="20" />  Property Type:<br /><b>{property_type ? capitalizeFirstLetter(property_type) : ''}</b></p>
                <hr/>
                <p><Icon icon={calendarMonthOutline} width="20"/>  Date:<br /><b>{new Date(createdate).toLocaleDateString()}</b></p>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default Deals;