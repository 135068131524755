import React from 'react';
import { Row, Col, Card, CardBody, FormGroup, Button, Label, ModalBody, Modal, ModalHeader, CardHeader } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { useState } from 'react';
import States from '../data/States';
import Select from 'react-select';
import { Icon } from '@iconify/react';
import percentOutline from '@iconify/icons-mdi/percent-outline';
import handCoinOutline from '@iconify/icons-mdi/hand-coin-outline';
import MaskedInput from 'react-text-mask';
import { useContext, useRef, useEffect } from 'react';
import { UserContext } from '../contexts/UserContext'
import { CapGuideReferralPartnerApi } from '../api/CapGuideApi';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import fileDocumentCheckOutline from '@iconify/icons-mdi/file-document-check-outline';
import { toast } from 'react-toastify';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {Helmet} from "react-helmet";

const ReferralForm = () => {

    const { user } = useContext(UserContext);

    const [referralFormConfirmModal, setReferralFormConfirmModal] = useState(false);

    const [submittingForm, setSubmittingForm] = useState(false);

    const [formInfo, setFormInfo] = useState({
        firstName: '', //client
        lastName: '', //client
        email: '', //client
        phoneNumber: '', //client
        propertyStreetAddress: '', //property
        propertyAptSuiteNum: '', //property
        propertyCity: '', //property
        propertyState: '', //property
        propertyZip: '', //property
        propertyType: '', //property
        loanAmount: null, //property
        commissionType: '', //property
        dealType: '',
        referralPartnerId: '', //hidden //property
        type: 'referred_client' //hidden //client
    })

    const propertyTypes = [
        {
            value: "1-4 family",
            label: "1-4 Family"
        },
        {
            value: 'co-op',
            label: 'Co-Op'
        },
        {
            value: 'condominium',
            label: 'Condominium'
        },
        {
            value: 'health care',
            label: 'Health Care'
        },
        {
            value: 'hotels/hospitality',
            label: 'Hotels/Hospitality'
        },
        {
            value: 'industrial',
            label: 'Industrial'
        },
        {
            value: 'land',
            label: 'Land'
        },
        {
            value: 'mixed-use',
            label: 'Mixed-Use'
        },
        {
            value: 'multi-family',
            label: 'Multi-Family'
        },
        {
            value: 'office',
            label: 'Office'
        },
        {
            value: 'owner-occupied',
            label: 'Owner-Occupied'
        },
        {
            value: 'retail',
            label: 'Retail'
        },
        {
            value: 'sfr',
            label: 'SFR'
        },
        {
            value: 'special purpose',
            label: 'Special Purpose'
        },
        {
            value: 'sro',
            label: 'SRO'
        }
    ];


    const handleValidSubmit = () => {
        if(!customValidateForm()) return
        let payload = {
            ...formInfo,
            phoneNumber: formInfo.phoneNumber.replace(/\D/g,'')
        }
        
        setSubmittingForm(true)

        let submittingFormToast = toast.loading('Processing form...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        
        CapGuideReferralPartnerApi.post('/forms/referralForm', payload)
        .then(res => {
            toast.update(submittingFormToast, 
                { 
                    render: 'Form successfully submitted',
                    type: "success", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            setReferralFormConfirmModal(true)
        })
        .catch(err => {
            toast.update(submittingFormToast, 
                { 
                    render: 'Error submitting form',
                    type: "error", 
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        })
        .finally(() => {
            setSubmittingForm(false)
        })
    }

    const formatOptionLabel = ({ value, label, customAbbreviation }) => (
        <div style={{ display: "flex" }} >
          <div>{value === 'commission' ? <><Icon icon={handCoinOutline} width="24" height="24"/> Commission</> : <><Icon icon={percentOutline} width="24" height="24"/> Client Discount</>}</div>
          {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>
            {customAbbreviation}
          </div> */}
        </div>
    );

    const [validState, setValidState] = useState({
        propertyType: true,
        commissionType: true,
        propertyState: true,
        phoneNumber: true,
        dealType: true,
        propertyStreetAddress: true
    })

    const customValidateForm = () => {

        let newValidState = {
            ...validState
        }

        if (!formInfo.propertyType)
            newValidState.propertyType = false

        if (!formInfo.commissionType)
            newValidState.commissionType = false;

        if (!formInfo.propertyState)
            newValidState.propertyState = false;
        
        if (!formInfo.propertyState)
            newValidState.propertyStreetAddress = false;
        
        if (!formInfo.dealType)
            newValidState.dealType = false;    

        if (formInfo.phoneNumber.replace(/\D/g,'').length < 10)
            newValidState.phoneNumber = false;

        let valid = true;
        
        for(let state in newValidState){
            if (newValidState[state] === false)
                valid = false
        }

        setValidState(newValidState)

        return valid
    }

    async function handleAutocompleteChange(address, action){
        setValidState({
            ...validState,
            propertyType: true
        })
        // console.log(address)
        const [place] = await geocodeByPlaceId(address.value.place_id);
        console.log(place)
        const { long_name: county = '' } =
        place.address_components.find(c => c.types.includes('administrative_area_level_2')) || "";
        const { long_name: postalCode = '' } =
        place.address_components.find(c => c.types.includes('postal_code')) || "";
        const { short_name: state = '' } =
        place.address_components.find(c => c.types.includes('administrative_area_level_1')) || "";
        const { long_name: locality = '' } =
        place.address_components.find(c => c.types.includes('locality')) || "";
        const { long_name: street = '' } =
        place.address_components.find(c => c.types.includes('route')) || "";
        const { long_name: streetNumber = '' } =
        place.address_components.find(c => c.types.includes("street_number")) || "";
        const { long_name: subpremise = '' } =
        place.address_components.find(c => c.types.includes("subpremise")) || "";
        const { long_name: sublocality = '' } =
        place.address_components.find(c => c.types.includes("sublocality")) || "";
        // console.log("street number", streetNumber, "street", street, "subpremise", subpremise, "locality", locality, "county", county, "state", state, "postalCode", postalCode);
        setFormInfo({
            ...formInfo,
            propertyStreetAddress: streetNumber + ' ' + street, //property
            propertyAptSuiteNum: subpremise, //property
            propertyCity: locality || sublocality, //property
            propertyState: state, //property
            propertyZip: postalCode //property
        })
    }

    return(
        <Row className='d-flex justify-content-center mt-2'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Referral Form | ${process.env.REACT_APP_HEADER_TITLE}`}</title>
            </Helmet>
            <Col md={10}>
                <Card className='mt-2'>
                    {/* <CardBody>
                    <Row className='d-flex justify-content-center mt-2'>
                        <Col xs={12} sm={11} md={11} lg={11} xl={8}>
                            <Card> */}
                                {/* <CardHeader>
                                    <h2 className='text-center'>Referral Form</h2>
                                </CardHeader> */}
                                <h2 className='pl-5 py-2'>Referral Form</h2>
                                <hr className='mx-5 my-0 p-0'/>
                                <CardBody className='px-5'>
                                        <h4>Contact Info</h4>
                                        <hr className='w-25 mx-0'/>
                                <AvForm 
                                beforeSubmitValidation={customValidateForm}
                                onValidSubmit={handleValidSubmit}>
                                    <Row>
                                        <Col md={6}>
                                            <AvField
                                                type="text"
                                                name="first-name"
                                                label="First Name *"
                                                placeholder="First name..."
                                                value={formInfo.firstName}
                                                onChange={(e) => setFormInfo({
                                                    ...formInfo,
                                                    firstName: e.target.value
                                                })}
                                                validate={{
                                                    required: {value: true, errorMessage: 'Please enter a first name'},
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <AvField
                                                type="text"
                                                name="last-name"
                                                label="Last Name *"
                                                placeholder="Last name..."
                                                value={formInfo.lastName}
                                                onChange={(e) => setFormInfo({
                                                    ...formInfo,
                                                    lastName: e.target.value
                                                })}
                                                validate={{
                                                    required: {value: true, errorMessage: 'Please enter a last name'},
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <AvField
                                                type="email"
                                                name="email"
                                                label="Email Address *"
                                                placeholder="Email..."
                                                value={formInfo.email}
                                                onChange={(e) => setFormInfo({
                                                    ...formInfo,
                                                    email: e.target.value
                                                })}
                                                validate={{
                                                    required: {value: true, errorMessage: 'Please enter an email address'},
                                                    email: {value: true, errorMessage: 'Invalid email'}
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="phone" style={{ color: `${!validState.phoneNumber ? '#ff4b4b' : ''}`}}>Phone *</Label>
                                            {/* <div style={{ width: '200px' }}> */}
                                                <MaskedInput
                                                    name="phone"
                                                    value={formInfo.phoneNumber}

                                                    onChange={(e) => {
                                                        if(e.target.value.replace(/\D/g,'').length === 10){
                                                            setValidState({
                                                                ...validState,
                                                                phoneNumber: true
                                                            })
                                                        }

                                                        setFormInfo({
                                                        ...formInfo,
                                                        phoneNumber: e.target.value
                                                    })}}
                                                    mask={[
                                                        '(',
                                                        /[1-9]/,
                                                        /\d/,
                                                        /\d/,
                                                        ')',
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    placeholder="(xxx) xxxx-xxxx"
                                                    className={`form-control ${!validState.phoneNumber ? 'invalid' : ''}`}
                                                />
                                            {/* </div> */}
                                                {!validState.phoneNumber ? <div className="invalid-text">Invalid phone number</div> : ''}
                                        </FormGroup>
                                        </Col>
                                    </Row>            

                                        {/* <AvField
                                            type="text"
                                            name="phone-number"
                                            label="Phone *"
                                            placeholder="Phone..."
                                            value={formInfo.phoneNumber}
                                            onChange={(e) => setFormInfo({
                                                ...formInfo,
                                                phoneNumber: e.target.value
                                            })}
                                            validate={{
                                                pattern: {value: '/^[0-9]+$/', errorMessage: 'Phone number can only contain numbers'},
                                                required: {value: true, errorMessage: 'Please enter a phone number'},
                                                minLength: {value: 10, errorMessage: 'Phone number must be a minimum of 10 digits'}
                                            }}
                                        /> */}

                                        <hr className='my-4'/>
                                        <h4>Property Info</h4>
                                        <hr className='w-25 mx-0'/>

                                        <Row>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Label for="property-type" style={{ color: `${!validState.propertyType ? '#ff4b4b' : ''}`}}>Property Type *</Label>
                                                {/* <div style={{width: '250px' }} className="mr-0"> */}
                                                    <Select
                                                        className={`${!validState.propertyType ? 'invalid-select' : 'react-select'}`}
                                                        classNamePrefix="react-select"
                                                        name="property-state"
                                                        onChange={(selection) => {
                                                            setValidState({
                                                                ...validState,
                                                                propertyType: true
                                                            })
                                                            setFormInfo({
                                                            ...formInfo,
                                                            propertyType: selection.value
                                                        })}}
                                                        value={propertyTypes.find(propertyType => propertyType.value === formInfo.propertyType) || ''}
                                                        isSearchable={false}
                                                        options={propertyTypes}
                                                    >
                                                    </Select>
                                                {/* </div> */}
                                                {(!validState.propertyType) && <div className="invalid-text">Please select property type</div>}
                                            </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                <Label for="property-street-address" style={{ color: `${(!validState.propertyStreetAddress && !formInfo.propertyStreetAddress) ? '#ff4b4b' : ''}`}}>Street Address *</Label>
                                                {/* <div style={{width: '150px' }} className="mr-0"> */}
                                                    <GooglePlacesAutocomplete 
                                                        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                                                        selectProps={{
                                                            components: {DropdownIndicator:() => null, IndicatorSeparator:() => null},
                                                            noOptionsMessage: ({inputValue}) =>  inputValue ? 'No options' : 'Start typing...',
                                                            placeholder: 'Street Address...',
                                                            name: "property-street-address",
                                                            value: formInfo.propertyStreetAddress ? { label: formInfo.propertyStreetAddress, value: formInfo.propertyStreetAddress } : '',
                                                            onChange: handleAutocompleteChange,
                                                            className: (!validState.propertyStreetAddress && !formInfo.propertyStreetAddress) ? 'invalid-select' : 'react-select',
                                                            classNamePrefix: "react-select"
                                                            }}
                                                        debounce={500}
                                                        withSessionToken
                                                    />
                                                {/* </div> */}
                                                {/* <AvField
                                                    type="text"
                                                    name="property-street"
                                                    label="Street Address *"
                                                    placeholder="Street Address..."
                                                    value={formInfo.propertyStreetAddress}
                                                    onChange={(e) => setFormInfo({
                                                        ...formInfo,
                                                        propertyStreetAddress: e.target.value
                                                    })}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'Please enter a street address'},
                                                    }}
                                                /> */}
                                                {(!validState.propertyStreetAddress&& !formInfo.propertyStreetAddress) && <div className="invalid-text">Please enter a street address</div>}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <AvField
                                                    type="text"
                                                    name="property-apt"
                                                    label="Apt/Suite"
                                                    placeholder="Apt/suite..."
                                                    value={formInfo.propertyAptSuiteNum}
                                                    onChange={(e) => setFormInfo({
                                                        ...formInfo,
                                                        propertyAptSuiteNum: e.target.value
                                                    })}
                                                    // validate={{
                                                    //     required: {value: true, errorMessage: 'Please enter a street address'},
                                                    // }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <AvField
                                                    type="text"
                                                    name="property-city"
                                                    label="City *"
                                                    placeholder="City..."
                                                    value={formInfo.propertyCity}
                                                    onChange={(e) => setFormInfo({
                                                        ...formInfo,
                                                        propertyCity: e.target.value
                                                    })}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'Please enter a city'},
                                                    }}
                                                />
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <Label for="property-state" style={{ color: `${(!validState.propertyState && !formInfo.propertyState) ? '#ff4b4b' : ''}`}}>State *</Label>
                                                {/* <div style={{width: '170px' }} className="mr-0"> */}
                                                    <Select
                                                        id="itemType"
                                                        className={`${(!validState.propertyState && !formInfo.propertyState) ? 'invalid-select' : 'react-select'}`}
                                                        classNamePrefix="react-select"
                                                        name="property-state"
                                                        onChange={(selection) => {
                                                            setValidState({
                                                                ...validState,
                                                                propertyState: true
                                                            })
                                                            setFormInfo({
                                                                ...formInfo,
                                                                propertyState: selection.value
                                                            })}}
                                                        value={States.find(state => state.value === formInfo.propertyState)}
                                                        isSearchable={true}
                                                        options={States}
                                                    >
                                                    </Select>
                                                {/* </div> */}
                                                {(!validState.propertyState && !formInfo.propertyState) && <div className="invalid-text">Please select state</div>}
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <AvField
                                                    type="text"
                                                    name="property-zip"
                                                    label="Zipcode *"
                                                    placeholder="Zip..."
                                                    value={formInfo.propertyZip}
                                                    onChange={(e) => setFormInfo({
                                                        ...formInfo,
                                                        propertyZip: e.target.value
                                                    })}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'Please enter a zipcode'},
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <hr className='my-4'/>
                                        <h4>Loan Info</h4>
                                        <hr className='w-25 mx-0'/>

                                        <Row>
                                            <Col md={6}>
                                                <AvField
                                                    type="number"
                                                    name="loan-amount"
                                                    label="Loan Amount *"
                                                    placeholder="Loan amount..."
                                                    value={formInfo.loanAmount}
                                                    onChange={(e) => setFormInfo({
                                                        ...formInfo,
                                                        loanAmount: e.target.value
                                                    })}
                                                    validate={{
                                                        required: {value: true, errorMessage: 'Please enter a loan amount'},
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Label for="deal-type" style={{ color: `${!validState.dealType ? '#ff4b4b' : ''}` }}>Deal Type *</Label>
                                                {/* <div style={{width: '200px' }} className="mr-0"> */}
                                                    <Select
                                                        id="deal-type"
                                                        classNamePrefix="react-select"
                                                        name="deal-type"
                                                        className={`${!validState.dealType ? 'invalid-select' : 'react-select'}`}
                                                        onChange={(selection) => {
                                                            setValidState({
                                                                ...validState,
                                                                dealType: true
                                                            })
                                                            setFormInfo({
                                                                ...formInfo,
                                                                dealType: selection.value
                                                            })}}
                                                        value={[
                                                            {label: 'Purchase', value: 'purchase'},
                                                            {label: 'Refinance', value: 'refinance'}
                                                        ].find(val => val.value === formInfo.dealType)}
                                                        options={[
                                                            {label: 'Purchase', value: 'purchase'},
                                                            {label: 'Refinance', value: 'refinance'}
                                                        ]}
                                                        isSearchable={false}
                                                    >
                                                    </Select>
                                                {/* </div> */}
                                                {(!validState.dealType) && <div className="invalid-text">Please select deal type</div>}
                                            </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Label for="commission-type" style={{ color: `${!validState.commissionType ? '#ff4b4b' : ''}`, whiteSpace: 'nowrap' }}>Commission Type *</Label>
                                                {/* <div style={{width: '200px' }} className="mr-0"> */}
                                                    <Select
                                                        id="commission-type"
                                                        classNamePrefix="react-select"
                                                        name="commission-type"
                                                        className={`${!validState.commissionType ? 'invalid-select' : 'react-select'}`}
                                                        formatOptionLabel={formatOptionLabel}
                                                        onChange={(selection) => {
                                                            setValidState({
                                                                ...validState,
                                                                commissionType: true
                                                            })
                                                            setFormInfo({
                                                                ...formInfo,
                                                                commissionType: selection.value
                                                            })}}
                                                        value={[
                                                            {label: 'Commission', value: 'commission'},
                                                            {label: 'Client Discount', value: 'client_discount'}
                                                        ].find(val => val.value === formInfo.commissionType)}
                                                        options={[
                                                            {label: 'Commission', value: 'commission'},
                                                            {label: 'Client Discount', value: 'client_discount'}
                                                        ]}
                                                        isSearchable={false}
                                                    >
                                                    </Select>
                                                {/* </div> */}
                                                {(!validState.commissionType) && <div className="invalid-text">Please select commission type</div>}
                                            </FormGroup>
                                            </Col>
                                        </Row>

                                    <FormGroup className='mt-2 d-flex justify-content-end'>
                                        <Button 
                                        color="formalNavy"
                                        disabled={submittingForm}
                                        >Submit</Button>
                                    </FormGroup>
                                </AvForm>
                                {/* </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    </CardBody>
                </Card>
                {referralFormConfirmModal && <ReferralFormConfirmModal isOpen={referralFormConfirmModal} />}
            </Col>
        </Row>
    )
}

const ReferralFormConfirmModal = ({formInfo, isOpen, toggle}) => {

    let history = useHistory();

    const [seconds, setSeconds] = useState(5);

    const modalTimeoutRef = useRef(null);

    useEffect(() => {

        if(!seconds)
            history.push('/deals')
        else
            modalTimeoutRef.current =  setTimeout(() => {
                setSeconds(seconds -1);
            }, 1000)
  
        // return () => {
        //     clearTimeout(sessionTimeoutRef.current)
        //     clearTimeout(modalTimeoutRef.current)
        // }

        return () => clearTimeout(modalTimeoutRef.current)
  
    }, [seconds])

    return(
        <Modal isOpen={isOpen} centered>
        {/* <ModalHeader className={`bg-${user?.referralPartnerStatus === 'pending' ? 'warning' : 'danger'} text-white`}>
            Your status is {user?.referralPartnerStatus}
        </ModalHeader> */}
        <ModalBody style={{ minHeight: '300px' }} className="text-center mt-2">
            <div><Icon icon={fileDocumentCheckOutline} style={{ outline: '5px solid #B0FBE6', borderRadius: '8px', color: '#0acf97' }} width='130' className='p-2 mb-2'  /></div>
            <h4>The referral form has been successfully submitted. <br />
            The new deals progress can be viewed on the deals page.</h4>
            <div>
             <b><i>Redirecting to deals page in {seconds} {seconds > 1 ? 'seconds' : 'second'}</i></b>
            </div>
        </ModalBody>
    </Modal>
    )
}

export default ReferralForm;