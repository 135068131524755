import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';

import { Container, Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';

import { isUserAuthenticated } from '../../helpers/authUtils';
import logo from '../../assets/images/partners-logo-on-white.svg';
import emailImg from '../../assets/images/mail_sent.svg';
import { UserContext } from '../../contexts/UserContext';
import { Helmet } from "react-helmet";

const ConfirmResetPasswordRequest = () => {

    let history = useHistory();
    let location = useLocation();
    const query = new URLSearchParams(location.search);

    const { capGuideReferralPartnerToken } = useContext(UserContext);

    useEffect(() => {
        if(!query.get('email'))
            history.replace('/account/login')
        if(capGuideReferralPartnerToken)
            history.replace(process.env.REACT_APP_DEFAULT_REDIRECT_ROUTE)
    }, [capGuideReferralPartnerToken])

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reset Password Email Sent | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
                <>
                <Row className='auth-window m-0'>
                    <Col xs={12} sm={6} md={5} lg={4} className="p-0 d-flex flex-column justify-content-between bg-auth">
                        <div className='d-flex w-100 justify-content-center mt-4'>
                        <img src={logo} alt="" height="50" />
                        </div>
                        <div className='mx-4' style={{color: '#00307c'}}>
                                    <Row className='justify-content-center'>
                                        <Col md={10}>

                                        <div className="text-center m-auto">
                                            <img src={emailImg} alt="" height="64" />

                                            <h4 className="text-navyRoyal text-center mt-4 font-weight-bold">
                                                Reset Password Email Sent
                                            </h4>
                                            <p className="mb-4 text-skyBlue">
                                                A link to reset your password has been sent to <b>{query.get('email')}</b>
                                            </p>

                                        </div>

                                        <FormGroup className="mt-3">
                                                <Button block color="formalNavy" onClick={() => history.push('/account/login')}>Back to Login</Button>
                                        </FormGroup>
                                        
                                        
                                        </Col>
                                    </Row>
                                {/* <Row className="mt-1">
                                <Col className="col-12 text-center">
                                    <p style={{ color: 'rgba(92,146,255,1)' }}>
                                        Don't have an account?{' '}
                                        <Link to="/account/register" className="ml-1" color='rgba(92,146,255,1)'>
                                            <b>Register</b>
                                        </Link>
                                    </p>
                                </Col>
                            </Row> */}
                            </div>
                            <div className='h-25'></div>
                    </Col>
                    <Col sm={6} md={7} lg={8} className='bg-column p-0 d-none d-sm-block'></Col>
                </Row>
            </>
        </React.Fragment>
        // <React.Fragment>
        //         <div className="account-pages mt-5 mb-5">
        //             <Container>
        //                 <Row className="justify-content-center">
        //                     <Col lg={5}>
        //                         <Card>
        //                             <div className="card-header pt-4 pb-4 text-center bg-primary">
        //                                 <a href="/">
        //                                     <span>
        //                                         <img src={logo} alt="" height="50" />
        //                                     </span>
        //                                 </a>
        //                             </div>

        //                             <CardBody className="p-4 position-relative">
        //                                 <div className="text-center m-auto">
        //                                     <img src={emailImg} alt="" height="64" />

        //                                     <h4 className="text-dark-50 text-center mt-4 font-weight-bold">
        //                                         Please check your email
        //                                     </h4>
        //                                     <p className="text-muted mb-4">
        //                                         A link to reset your password has been sent to <b>{query.get('email')}</b>
        //                                     </p>

        //                                     <p className="text-center">
        //                                         <Link className="btn btn-primary" to="/account/login">
        //                                             Back to Login
        //                                         </Link>
        //                                     </p>
        //                                 </div>
        //                             </CardBody>
        //                         </Card>
        //                     </Col>
        //                 </Row>
        //             </Container>
        //         </div>
        // </React.Fragment>
    );
}

export default connect()(ConfirmResetPasswordRequest);
