import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const RestrictedComponent = (props) => {

    const { user } = useContext(UserContext);

    useEffect(() => {
        if(user){
            if(user.referralPartnerStatus === 'approved')
                props.history.replace('/deals')
        }
    }, [user])

    return(
        <div className='h-100 w-100 bg-restricted'>

        </div>
    )
}

export default RestrictedComponent;